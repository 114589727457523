import {getOrCreateDataObject} from 'o365.vue.ts';
import AzApi from 'o365.modules.azure.storage.api.ts';


const filesClient =  new AzApi.TableClient({
    accountName: 'omega365appcache',
    entityName: 'files',
});

const templatesClient =  new AzApi.TableClient({
    accountName: 'omega365appcache',
    entityName: 'apps',
});
const appsClient =  new AzApi.TableClient({
    accountName: 'omega365appcache',
    entityName: 'apps',
});

const fields = [
    {name:"RowKey"},
    {name:"PartitionKey",caption:"File Name"},
    {name:"Version",type:"number",sortOrder:1},
    {name:"PublishDescription",caption:"Description"},
    {name:"CreatedBy"},
    {name:"Location"},
    {name:"Timestamp",type:"datetime",caption:"Created"},
];

const versions = new Map();

export default function getVersionsDataObject(pFileName:string,pType:string = "files",pId:string="id",pVersion:number=0){

   
    const dsObject =  getDataObject(pFileName,pId);
    
    setDataObjectData(dsObject,pFileName,pType, pVersion);

    return dsObject;

}

export function getDataObject(pFileName:string,pId:string="id"){
    return  getOrCreateDataObject({
        id:'dsVersions_'+pFileName+"_"+pId,
        fields:fields,
        selectFirstRowOnLoad:true,
       // clientSideFiltering:true
    });
}

export async function setDataObjectData(dsObject,pFileName,pType,pVersion=0){
    dsObject.state.isLoading = true;
    return  getVersionsData(pFileName,pType,pVersion).then(data=>{

        dsObject.enableClientSideHandler(data.data);

        dsObject.load();
        dsObject.state.isLoading = false;
    
    })

}


export async function getVersionsData(pFileName:string, pType:string = "files", version:number = 0){
    if(versions.has(pFileName)){
        return versions.get(pFileName);
    }
    const versionFilter = version ? ` and Version le ${version}` : "";

    let vResult = [] as any;
    if(pType === "files"){
        vResult = await filesClient.queryTableEntities({filter:`PartitionKey eq '${pFileName}'` + versionFilter});
    }else if(pType === 'template'){     
        vResult = await templatesClient.queryTableEntities({filter:`PartitionKey eq '_${pFileName}'`});
        //adding missing CreatedBy
        vResult = await idsToNames(vResult); 
    } else{
        vResult = await appsClient.queryTableEntities({filter:`ID eq '${pFileName}'` + versionFilter});
    }
  

    versions.set(pFileName,vResult);

    return versions.get(pFileName);
}

export function getDBDataObject(pFileName:string,pId:string="id"){
    return getOrCreateDataObject({
        id:'dsVersions_'+pFileName+"_"+pId,
        viewName: 'sviw_Database_VersionWithDataChange',
        fields: [{
            name: 'PrimKey',
            type: 'uniqueidentifier'
            },
            {
                name: 'Updated',
                type: 'datetime'
            },
            {
                name: 'CreatedByName',
                type: 'string'
            },
            {
                name: 'Version',
                type: 'number',
                sortOrder: 1,
                sortDirection: 'desc'
            },
            {
                name: 'DBObjectID',
                type: 'string'
            },
            {
                name: 'TSQLCommand',
                type: 'string'
            },
            {
                name: 'Description',
                type: 'string'
            },
        ],
        maxRecords: 100,
        selectFirstRowOnLoad:true
    });
}

const dsPersonsVDO = getOrCreateDataObject({
        id: 'dsPersonsVDO',
        viewName: 'stbv_System_Persons',
        fields: [{
            name: 'ID',
            type: 'number'
            },
            {
                name: 'Name',
                type: 'string'
            }
        ],
        maxRecords: 1000,
        selectFirstRowOnLoad:true
});

const idsToNames = async (pResult: any) => {
    let result = Array<any>;

    const ids = pResult.data.map((item: any) => item.UpdatedByID).join(',');
    dsPersonsVDO.recordSource.whereClause = `ID IN (${ids})`;

    try {
        await dsPersonsVDO.load();
        const lookup = dsPersonsVDO.data.reduce((acc: Record<number, string>, item: any) => {
            acc[item.ID] = item.Name;
            return acc;
        }, {});
        result = pResult.data.map((item: any) => ({
            ...item,
            CreatedBy: lookup[item.UpdatedByID] || null
        }));
        return { data: result };
    } catch (error) {
        console.error('Error loading data source:', error);
        return { data: [] };
    } 
};